import React from "react";
import Layout from "../../Layout";
import "./style.scss";
import {
  acedemic_incharges,
  cultural_incharges,
  head_vicehead_data,
  sports_incharge,
  editorial_team,
  multimedia_art_community_welfare,
  house_post_bearers,
} from "./data-2024";

function SchoolCouncilPrimary() {
  function GetSchoolLeadersJSX(data = []) {
    const leadersJSX = data.map((item) => (
      <div key={item.position} className="schoolcouncil__leaders__row">
        <div className="schoolcouncil__leaders__pos">{item.position}</div>
        <div className="schoolcouncil__leaders__nameclass">
          {item.name}
          {item.class && ` | ${item.class}`}
        </div>
      </div>
    ));
    return leadersJSX;
  }

  return (
    <Layout>
      <div className="schoolcouncil">
        <br />
        <br />
        <br />

        <h1 className="heading">Student Council Members</h1>
        <h2 className="heading">HEAD AND VICE HEAD </h2>
        <div className="schoolcouncil__leaders">
          {GetSchoolLeadersJSX(head_vicehead_data)}
        </div>

        <h2 className="heading">ACADEMIC INCHARGE & DISCIPLINE INCHARGES</h2>
        <div className="schoolcouncil__leaders">
          {GetSchoolLeadersJSX(acedemic_incharges)}
        </div>

        <h2 className="heading">CULTURAL INCHARGES </h2>
        <div className="schoolcouncil__leaders">
          {GetSchoolLeadersJSX(cultural_incharges)}
        </div>

        <h2 className="heading">SPORTS INCHARGES </h2>
        <div className="schoolcouncil__leaders">
          {GetSchoolLeadersJSX(sports_incharge)}
        </div>

        <h2 className="heading">EDITORIAL TEAM </h2>
        <div className="schoolcouncil__leaders">
          {GetSchoolLeadersJSX(editorial_team)}
        </div>

        <h2>MULTIMEDIA, ART, COMMUNITY & WELFARE</h2>
        <div className="schoolcouncil__leaders">
          {GetSchoolLeadersJSX(multimedia_art_community_welfare)}
        </div>

        <h1 className="heading">House Post Bearers (2023-24) </h1>

        <h2 className="heading">HONESTY HOUSE </h2>
        <div className="schoolcouncil__leaders">
          {GetSchoolLeadersJSX(house_post_bearers["HONESTY HOUSE"])}
        </div>

        <h2 className="heading">FREEDOM HOUSE </h2>
        <div className="schoolcouncil__leaders">
          {GetSchoolLeadersJSX(house_post_bearers["FREEDOM HOUSE"])}
        </div>

        <h2 className="heading">FRIENDSHIP HOUSE </h2>
        <div className="schoolcouncil__leaders">
          {GetSchoolLeadersJSX(house_post_bearers["FRIENDSHIP HOUSE"])}
        </div>

        <h2 className="heading">SINCERITY HOUSE </h2>
        <div className="schoolcouncil__leaders">
          {GetSchoolLeadersJSX(house_post_bearers["SINCERITY HOUSE"])}
        </div>
      </div>
    </Layout>
  );
}

export default SchoolCouncilPrimary;
