export const head_vicehead_data = [
  {
    position: "HEAD BOY (PRIMARY SCHOOL)",
    name: "RAYHAANG SUBBA",
    class: "V",
  },
  {
    position: "HEAD GIRL (PRIMARY SCHOOL)",
    name: "KASHVI ADHIKARI",
    class: "V",
  },
  {
    position: "VICE HEAD BOY (PRIMARY SCHOOL)",
    name: "ATHARV SINGH GAHARWAR",
    class: "IV",
  },
  {
    position: "VICE HEAD GIRL (PRIMARY SCHOOL)",
    name: "SAMAIRA JAIN",
    class: "IV",
  },
  {
    position: "HEAD BOY (SENIOR SCHOOL)",
    name: "MUKUL CHAUDHARY",
    class: "XII",
  },
  {
    position: "HEAD GIRL (SENIOR SCHOOL)",
    name: "HARSHITA SINGH",
    class: "XII",
  },
  {
    position: "VICE HEAD BOY (SENIOR SCHOOL)",
    name: "DAKSH CHHAWAL",
    class: "XI",
  },
  {
    position: "VICE HEAD GIRL (SENIOR SCHOOL)",
    name: "BHAVYA JHA",
    class: "XI",
  },
];

export const acedemic_incharges = [
  {
    position: "DISCIPLINE INCHARGE (PRIMARY SCHOOL)",
    name: "SOHAM PANDA",
    class: "V",
  },
  {
    position: "DISCIPLINE INCHARGE (PRIMARY SCHOOL)",
    name: "ANAYAA DHAL",
    class: "V",
  },
  {
    position: "DISCIPLINE CAPTAIN (SENIOR SCHOOL)",
    name: "NAVYA RANJAN",
    class: "XII",
  },
  {
    position: "DISCIPLINE CAPTAIN (SENIOR SCHOOL)",
    name: "ANIKA GUPTA",
    class: "XII",
  },
  {
    position: "DISCIPLINE CAPTAIN (SENIOR SCHOOL)",
    name: "PRATYUSH MALLICK",
    class: "XII",
  },
  {
    position: "DISCIPLINE VICE CAPTAIN (SENIOR SCHOOL)",
    name: "UTKARSH BHARTI",
    class: "XI",
  },
  {
    position: "DISCIPLINE VICE CAPTAIN (SENIOR SCHOOL)",
    name: "SIYA DAHIYA",
    class: "XI",
  },
  {
    position: "DISCIPLINE VICE CAPTAIN (SENIOR SCHOOL)",
    name: "AASHNA TRIPATHI",
    class: "XI",
  },
];

export const cultural_incharges = [
  {
    position: "CULTURAL INCHARGE (PRIMARY SCHOOL)",
    name: "VATSAL YADAV",
    class: "V",
  },
  {
    position: "CULTURAL INCHARGE (PRIMARY SCHOOL)",
    name: "AANAVI BHADRA",
    class: "V",
  },
  {
    position: "CULTURAL CAPTAIN (SENIOR SCHOOL)",
    name: "AVNI PANDEY",
    class: "XII",
  },
  {
    position: "CULTURAL CAPTAIN (SENIOR SCHOOL)",
    name: "SAUMYA RAWAT",
    class: "XII",
  },
  {
    position: "CULTURAL VICE CAPTAIN (SENIOR SCHOOL)",
    name: "ADISH BABURAJ",
    class: "XI",
  },
  {
    position: "CULTURAL VICE CAPTAIN (SENIOR SCHOOL)",
    name: "NAZISH PAHWA",
    class: "XI",
  },
];

export const sports_incharge = [
  {
    position: "SPORTS INCHARGE (PRIMARY SCHOOL)",
    name: "VISHANTAK HUMAR",
    class: "V",
  },
  {
    position: "SPORTS INCHARGE (PRIMARY SCHOOL)",
    name: "DHANVI DUBEY",
    class: "V",
  },
  {
    position: "SPORTS CAPTAIN (SENIOR SCHOOL)",
    name: "SAIANSH MUKHERJEE",
    class: "XII",
  },
  {
    position: "SPORTS CAPTAIN (SENIOR SCHOOL)",
    name: "YOGITA SINGH",
    class: "XII",
  },
  {
    position: "SPORTS VICE CAPTAIN (SENIOR SCHOOL)",
    name: "DAKSH JAMWAL",
    class: "XI",
  },
  {
    position: "SPORTS VICE CAPTAIN (SENIOR SCHOOL)",
    name: "ANVI NIRWAL",
    class: "XI",
  },
];

export const editorial_team = [
  {
    position: "EDITOR-IN-CHIEF (SENIOR SCHOOL)",
    name: "CHARVI RUDHRA",
    class: "XII",
  },
  {
    position: "VICE EDITOR (SENIOR SCHOOL)",
    name: "ISHAN ROY",
    class: "XI",
  },
  {
    position: "VICE EDITOR (SENIOR SCHOOL)",
    name: "PRATITI RAIT",
    class: "XI",
  },
];

export const multimedia_art_community_welfare = [
  {
    position: "MULTIMEDIA CAPTAIN (SENIOR SCHOOL)",
    name: "SATVIK MALVIYA",
    class: "XII",
  },
  {
    position: "MULTIMEDIA CAPTAIN (SENIOR SCHOOL)",
    name: "ZAYN KHAN",
    class: "XII",
  },
  {
    position: "MULTIMEDIA VICE CAPTAIN (SENIOR SCHOOL)",
    name: "MANAS PANDEY",
    class: "XI",
  },
  {
    position: "MULTIMEDIA VICE CAPTAIN (SENIOR SCHOOL)",
    name: "PRAKRIT VERMA",
    class: "XI",
  },
  {
    position: "ART CAPTAIN (SENIOR SCHOOL)",
    name: "TANIYA SINGH",
    class: "XII",
  },
  {
    position: "ART VICE CAPTAIN (SENIOR SCHOOL)",
    name: "ANUGYA TIWARI",
    class: "XI",
  },
  {
    position: "ART VICE CAPTAIN (SENIOR SCHOOL)",
    name: "PRASHANSA  SINGH",
    class: "XI",
  },
  {
    position: "COMMUNITY & WELFARE CAPTAIN (SENIOR SCHOOL)",
    name: "PRIYANSHI BINDRA",
    class: "XII",
  },
  {
    position: "COMMUNITY & WELFARE CAPTAIN (SENIOR SCHOOL)",
    name: "SHAGUN JHA",
    class: "XII",
  },
  {
    position: "COMMUNITY & WELFARE CAPTAIN (SENIOR SCHOOL)",
    name: "VAISHNAVI NAIR",
    class: "XII",
  },
  {
    position: "COMMUNITY & WELFARE VICE CAPTAIN (SENIOR SCHOOL)",
    name: "CHETNA PAWAR",
    class: "XI",
  },
  {
    position: "COMMUNITY & WELFARE VICE CAPTAIN (SENIOR SCHOOL)",
    name: "LABDHI KAUSHAL MEHTA",
    class: "XI",
  },
  {
    position: "COMMUNITY & WELFARE VICE CAPTAIN (SENIOR SCHOOL)",
    name: "CHARITA SINGH",
    class: "XI",
  },
];

export const house_post_bearers = {
  "HONESTY HOUSE": [
    {
      position: "HOUSE CAPTAIN (PRIMARY SCHOOL)",
      name: "SAANVI SINGH",
      class: "V",
    },
    {
      position: "VICE HOUSE CAPTAIN (PRIMARY SCHOOL)",
      name: "PRIYANSH GANGULY",
      class: "IV",
    },
    {
      position: "HOUSE CAPTAIN (SENIOR SCHOOL)",
      name: "ANUSHA KESTWAL",
      class: "XII",
    },
    {
      position: "HOUSE CAPTAIN (SENIOR SCHOOL)",
      name: "ARCHIE MUDGAL",
      class: "XII",
    },
    {
      position: "HOUSE VICE CAPTAIN (SENIOR SCHOOL)",
      name: "NIKHIL KUMAR MOHANTA",
      class: "XI",
    },
    {
      position: "HOUSE VICE CAPTAIN (SENIOR SCHOOL)",
      name: "MANNAT KAUR",
      class: "XI",
    },
  ],
  "FREEDOM HOUSE": [
    {
      position: "HOUSE CAPTAIN (PRIMARY SCHOOL)",
      name: "AATHIRA KOTRAVAI K",
      class: "V",
    },
    {
      position: "VICE HOUSE CAPTAIN (PRIMARY SCHOOL)",
      name: "LOVIKA PASRICHA",
      class: "IV",
    },
    {
      position: "HOUSE CAPTAIN (SENIOR SCHOOL)",
      name: "KASHISH MALIK",
      class: "XII",
    },
    {
      position: "HOUSE CAPTAIN (SENIOR SCHOOL)",
      name: "PRADEEPTA NAIK",
      class: "XII",
    },
    {
      position: "HOUSE VICE CAPTAIN (SENIOR SCHOOL)",
      name: "PANAV BARANWAL",
      class: "XI",
    },
    {
      position: "HOUSE VICE CAPTAIN (SENIOR SCHOOL)",
      name: "MANYA CHAUHAN",
      class: "XI",
    },
  ],
  "FRIENDSHIP HOUSE": [
    {
      position: "HOUSE CAPTAIN (PRIMARY SCHOOL)",
      name: "UDHBHAV ANIRUDH",
      class: "V",
    },
    {
      position: "VICE HOUSE CAPTAIN (PRIMARY SCHOOL)",
      name: "GAURIKA",
      class: "IV",
    },
    {
      position: "HOUSE CAPTAIN (SENIOR SCHOOL)",
      name: "VANIKA CHAUHAN",
      class: "XII",
    },
    {
      position: "HOUSE CAPTAIN (SENIOR SCHOOL)",
      name: "UTKARSHA SINGH",
      class: "XII",
    },
    {
      position: "HOUSE VICE CAPTAIN (SENIOR SCHOOL)",
      name: "DEV SACHDEVA",
      class: "XI",
    },
    {
      position: "HOUSE VICE CAPTAIN (SENIOR SCHOOL)",
      name: "GITIKA NEGI",
      class: "XI",
    },
  ],
  "SINCERITY HOUSE": [
    {
      position: "HOUSE CAPTAIN (PRIMARY SCHOOL)",
      name: "NIHARIKA SHANKAR",
      class: "V",
    },
    {
      position: "VICE HOUSE CAPTAIN (PRIMARY SCHOOL)",
      name: "NAMISH BANSAL",
      class: "IV",
    },
    {
      position: "HOUSE CAPTAIN (SENIOR SCHOOL)",
      name: "DIVYA GUPTA",
      class: "XII",
    },
    {
      position: "HOUSE CAPTAIN (SENIOR SCHOOL)",
      name: "SHUBHAM SAHA",
      class: "XII",
    },
    {
      position: "HOUSE VICE CAPTAIN (SENIOR SCHOOL)",
      name: "PARTH GAMBHIR",
      class: "XI",
    },
    {
      position: "HOUSE VICE CAPTAIN (SENIOR SCHOOL)",
      name: "ANUSHKA JAMWAL",
      class: "XI",
    },
  ],
};
